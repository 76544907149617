import { BW_COLOR_MAIN, BW_LANGUAGE_IT } from 'src/libs/qb-brand-web-components'

const brandConfig = {
  brandId: 'renault',
  name: 'Renault',
  brandSymbol: 're-w@rd',
  websiteUrl: 'mymeganetech.renault.it',
  termsUrl: 'https://re-ward.renault.it/regolamento_programma_re-ward.pdf',
  privacyPolicyUrl: 'https://www.renault.it/privacy/privacy-re-ward.html',
  cookiePolicyUrl: 'https://www.renault.it/cookie-policy.html',
  redirectUrl:
    'https://re-ward.renault.it/scopri-il-programma.html#come-funziona',
  faqUrl: 'https://re-ward.renault.it/scopri-il-programma.html#faq',
  theme: {
    colors: {
      main: '#000000',
      accent: '#EFDF00'
    }
  },
  supportedLanguages: [BW_LANGUAGE_IT],
  features: {
    disableQRCode: true,
    disableHowToEarnRewards: true,
    disableExchangeSurvey: true,
    mandateReadPrivacyPolicy: true,
    enableCOEStorefront: true
  },
  cognito: {
    region: 'eu-central-1',
    poolId: 'eu-central-1_UT67Hr3nA',
    clientId: '57c9l1rrk820d0l5jbv6vv8jqr',
    poolDomain: 'renault.auth.eu-central-1.amazoncognito.com',
    redirectUri: 'https://re-ward.renault.it/login'
  },
  prefTextColor: BW_COLOR_MAIN,
  gaTrackingId: 'UA-23042710-1',
  hotjarId: '3114039',
  googleTagManagerId: 'GTM-M2RS4PT',
  invertBg: true,
  gtmIdForDataLayer: 'GTM-NNLG55H',
  dataLayer: {
    googleAccount: 'UA-23042710-1',
    businessType: 'loyalty',
    languageCode: 'it',
    countryCode: 'IT'
  },
  campaignIdsToEnablePointBurn: [285, 301] // @TODO: hard coded campaign id to disable points burn
}

export default brandConfig
