import {
  BW_COLOR_ACCENT,
  BW_COLOR_WHITE,
  CSS_TEXT_LOWERCASE,
  deepMerge
} from 'src/libs/qb-brand-web-components'

import defaultConfig from '../common/customConfig'
import { buildFontFace } from './fonts/NouvelR/buildFontFace'
import brandConfig from 'brandConfig'

const customConfig = {
  customCss: {
    isRectangular: true,
    textTransform: CSS_TEXT_LOWERCASE,
    invertColors: true,
    textColor: BW_COLOR_WHITE,
    isCustom: true
  },
  coinIcon: 'G_ICONS_24px_COINS_v4',
  exchangeIcon: 'G_ICONS_24px_COINS_CASHBACK_v4',
  coinsIcon: 'G_ICONS_24px_COINS_v4',
  shopBagIcon: 'G_ICONS_24px_SHOP_BAG_v4',
  nftIcon: 'G_ICONS_24px_NFT_v4',
  nftsIcon: 'G_ICONS_24px_NFT_v4',
  iconColor: BW_COLOR_ACCENT,
  paintColorsIcon: 'G_ICONS_24px_PAINT_COLORS_v4',
  transactionsIcon: 'G_ICONS_24px_LIST_BULLETED_v4',
  giftCardIcon: 'G_ICONS_24px_SHOP_BAG_v4',
  disableCoeIcon: true,
  fontFace: buildFontFace(),
  fontFamily: 'NouvelR',
  receivedPointsButtonLabel: 'brand:common.understand',
  receiveGiftsLabel: 'brand:common.awesome',
  accountSaveSuccessLabel: 'brand:common.awesome',
  oopsLabel: 'brand:common.oops',
  goBackLabel: 'brand:common.go-back',
  continueLabel: 'brand:common.continue',
  acceptPrivacyPolicy: 'brand:common.accept-privacy-policy',
  resetItLabel: 'brand:login.reset-it',
  loginPageTitle: '',
  loginPageDescLabel: '',
  forgotPasswordLabel: 'brand:forgot-password.enter-your-email-for-reset-link',
  signWithSocialLabel: 'brand:forgot-password.signed-up-with-social-account',
  resetPasswordLabel: 'brand:reset-password.reset-password',
  youDidItLabel: 'brand:reset-password.you-did-it',
  goToLoginLabel: 'brand:reset-password.go-to-login',
  checkYourEmailLabel: 'brand:sign-up.check-your-email-inbox',
  youAreVerifiedLabel: 'brand:sign-up.you-are-verified',
  youSuccessfullyVerifiedLabel: 'brand:sign-up.you-successfully-verified-email',
  accountCreationFailedLabel: 'brand:sign-up.account-creation-fail',
  couldNotFindPageLabel:
    'brand:page-not-found.could-not-find-what-you-are-looking-for',
  viewQrCodeLabel: 'brand:user-account.view-qr-code',
  accountNumberQrCodeLabel: 'brand:user-account.account-number-qr-code',
  scanQrCodeDescLabel: 'brand:user-account.scan-qr-code-desc',
  deleteAccountLabel: 'brand:user-account.delete-account',
  noRedemptionOptionLabel: 'brand:redeem.no-redemption-options-available',
  codeUsedLabel: 'brand:overview.code-used',
  youEarnedItTitle: 'brand:overview.you-earned-it',
  eventTypeLabel: 'brand:transactions.event-type',
  forgotPasswordSuccessLabel: 'brand:message.forgot-password-success',
  passwordResetSuccessLabel: 'brand:message.password-reset-success',
  rewardCodeAlreadyExpired: 'brand:message.reward-code-already-expired',
  rewardCodeUsedLabel: 'brand:message.reward-code-used',
  emailAlreadyExistsLabel: 'brand:error.email-already-exists',
  triedTooManyTimesLabel: 'brand:error.tried-too-many-times',
  scanCodeAndReceivePointsLabel: 'brand:qr-code.scan-code-and-receive-points',
  manageAccounts: 'brand:exchange.manage-accounts',
  manageConnectedAccounts: 'brand:exchange.manage-connected-accounts',
  scanQrCodeConnectAccountLabel: 'brand:exchange.scan-qr-code-connect-account',
  viewShopLabel: 'brand:exchange.view-shop',
  welcomeToShopLabel: 'brand:exchange.welcome-to-shop',
  yourPreviousSubmissionsLabel: 'brand:surveys.your-previous-submissions',
  howToEarnLabel: 'brand:reward.how-to-earn-rewards',
  receivedPointsDesc: 'brand:message.received-points',
  privacyPolicyLabel: 'brand:common.privacy-policy',
  privacyPolicyUrl: brandConfig.privacyPolicyUrl,
  receiveYourRewardLabel: 'brand:reward.receive-your-reward',
  receiveRewardLabel: 'brand:common.confirm',
  enterYourRewardCodeLabel: '',
  cancelLabel: 'brand:common.cancel',
  enterRewardCodeLabel: 'brand:reward.enter-your-reward-code',
  redeemPointsLinkCss: CSS_TEXT_LOWERCASE,
  allTransactionsLabelCss: CSS_TEXT_LOWERCASE,
  signOutLabelCss: CSS_TEXT_LOWERCASE,
  pointsOverviewLabel: 'brand:nav-header.points-overview',
  allYourTransactionsLabel: 'brand:transactions.all-your-transactions',
  receiveFollowingRewardsLabel: 'brand:exchange.receive-following-reward',
  exchangeText: 'brand:exchange.exchange-points',
  exchangeButtonText: 'brand:exchange.exchange-points-action',
  carefullyReadTermsLabel: 'brand:sign-up.carefully-read-terms',
  termsAcceptPageTitle: 'brand:common.privacy',
  disableToggleMoreNftDetails: true,
  redeemPointsLabel: 'brand:redeem.redeem-points',
  redeemYourPointsLabel: 'brand:redeem.redeem-your-points',
  exchangeYourText: 'brand:exchange.exchange-points',
  exploreMoreRewardsLabel: 'brand:redeem.explore-more-rewards',
  redeemPointsDescLabel: 'brand:redeem.redeem-points-desc',
  redeemPointsLinkLabel: 'brand:redeem.redeem-points-link',
  redeemPointsConfirmLabel: 'brand:redeem.redeem-points-link',
  resendVerificationEmailLabel: 'brand:common.resend-verification-email',
  disableCodeToPointsAction: true,
  hideSignUpSuccessTitle: true,
  hideRedemptionSuccessTitle: true,
  hideRedemptionSuccessSubmit: true,
  overviewEnterRewardCodeLabel: 'brand:reward.enter-reward-code'
}

export default deepMerge(defaultConfig, customConfig)
