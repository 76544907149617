import React, { memo } from 'react'
import { css } from '@emotion/react'

import Header from '../../../components/shared/header/Header'

const HeaderWrapper = (props) => {
  return <Header customCss={CSS_HEADER} iconCss={CSS_HEADER_ICON} {...props} />
}

export default memo(HeaderWrapper)

const CSS_HEADER = css`
  padding: 0 15px;
  height: 50px;
  background: black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
`

const CSS_HEADER_ICON = css`
  height: 35px;
  width: auto;
  margin-top: 5px;
`
