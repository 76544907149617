export const buildFontFace = () => `
  @font-face {
    font-family: "NouvelR";
    src: local("NouvelR"),
    url("../fonts/NouvelR/assets/NouvelR-Light.ttf") format("truetype");
    font-weight: 300;
  }

  @font-face {
    font-family: "NouvelR";
    src: local("NouvelR"),
    url("../fonts/NouvelR/assets/NouvelR-Regular.ttf") format("truetype");
    font-weight: 400;
  }

  @font-face {
    font-family: "NouvelR";
    src: local("NouvelR"),
    url("../fonts/NouvelR/assets/NouvelR-Semibold.ttf") format("truetype");
    font-weight: 600;
  }

  @font-face {
    font-family: "NouvelR";
    src: local("NouvelR"),
    url("../fonts/NouvelR/assets/NouvelR-Bold.ttf") format("truetype");
    font-weight: 700;
  }

  @font-face {
    font-family: "NouvelR";
    src: local("NouvelR"),
    url("../fonts/NouvelR/assets/NouvelR-Extrabold.ttf") format("truetype");
    font-weight: 800;
  }
`
