import React, { memo, Suspense } from 'react'
import { ThemeProvider, Global } from '@emotion/react'
import {
  defaultTheme,
  deepMerge,
  BWOutsideLink,
  BWPanel,
  BWPanelHeader,
  BWUnauthorizedContainer,
  BWUnauthorizedContent
} from 'src/libs/qb-brand-web-components'

import brandConfig from 'brandConfig'
import Loading from '../../../components/shared/loading/Loading'
import { globalStyles } from '../../../styles/global'
import HeaderWrapper from './HeaderWrapper'
import { prependHttpsIfNotExists } from '../../../util/navigation.helpers'

const RootWrapper = () => {
  const link = 'www.renault.it'
  return (
    <ThemeProvider theme={deepMerge(defaultTheme, brandConfig.theme)}>
      <Global styles={globalStyles} />
      <Suspense fallback={<Loading main />}>
        <BWUnauthorizedContainer css={{ gap: 10 }}>
          <HeaderWrapper />
          <BWUnauthorizedContent>
            <BWPanel data-testid='welcome'>
              <BWPanelHeader
                description={
                  <>
                    re-w@rd è terminato, grazie per l’interesse. Resta informato
                    su tutte le novità e le prossime elettrizzanti iniziative su{' '}
                    <BWOutsideLink
                      label={link}
                      to={prependHttpsIfNotExists(link)}
                    />
                  </>
                }
              />
            </BWPanel>
          </BWUnauthorizedContent>
        </BWUnauthorizedContainer>
      </Suspense>
    </ThemeProvider>
  )
}

export default memo(RootWrapper)
